.hero-name {
  display: inline-block;
}

.hero-title {
  display: inline-block;
}

.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #664;
  min-height: 100vh;
}

.hero-intro {
  min-width: 50%;
  display: inline-block;
}

.hero-portrait {
  display: inline-block;
}

.hero-portrait-image {
  height: auto;
  width: auto;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.45);
}

.hero-container h1 {
  font-size: 5.5vw;
  color: #cca;
  margin: 0;
  text-shadow: 4px 4px rgb(50, 50, 50, 0.8);
}

.hero-container h5 {
  font-weight: 300;
  width: 8vw;
  color: #cca;
  text-align: center;
  margin: 2.5vh auto;
  padding-bottom: 1.5vh;
  background-color: rgb(50, 50, 50, 0.6);
  text-shadow: 4px 4px rgb(50, 50, 50, 0.8);
  border-right: 2px solid #cca;
  white-space: nowrap;
  overflow: hidden;
}

.anim-typewriter {
  animation: typewriter 2s steps(30) 0s 1 normal both,
    blinkTextCursor 500ms steps(15) infinite normal;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 85%;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: #cca;
  }
  to {
    border-right-color: transparent;
  }
}

@media (min-width: 200px) {
  .hero-container {
    flex-direction: column;
  }
  .hero-portrait {
    max-height: 75%;
    max-width: 75%;
  }
  .hero-container h1 {
    font-size: 10vw;
    text-shadow: 2px 2px rgb(50, 50, 50, 0.8);
  }
  .hero-container h5 {
    font-size: 8vw;
    text-shadow: 2px 2px rgb(50, 50, 50, 0.8);
  }
  .hero-portrait-image {
    margin-top: 2.25vh;
    max-width: 100%;
  }
  @keyframes typewriter {
    from {
      width: 0;
    }
    to {
      width: 99%;
    }
  }
}

@media (min-width: 769px) {
}

@media (min-width: 1025px) {
  .hero-container {
    flex-direction: row;
  }
  .hero-portrait {
    max-height: 75%;
    max-width: 75%;
  }
  .hero-container h1 {
    font-size: 6.5vw;
    text-shadow: 4px 4px rgb(50, 50, 50, 0.8);
  }
  .hero-container h5 {
    font-size: 4.5vw;
    text-shadow: 4px 4px rgb(50, 50, 50, 0.8);
  }
  .hero-portrait-image {
    margin-top: 2.25vh;
    max-width: 75%;
  }
  @keyframes typewriter {
    from {
      width: 0;
    }
    to {
      width: 85%;
    }
  }
}
