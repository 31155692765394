.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-home {
  margin: 0;
  font-size: 1.45em;
}

.nav-home a {
  padding: 0 15px;
}

.nav-list a {
  padding: 10px 15px;
}

.nav-main {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background-color: #cca;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.45);
  position: fixed;
  opacity: 0;
  z-index: 1;
}

.nav-home a,
.nav-list a {
  text-align: center;
  color: #664;
  font-size: 20px;
  font-weight: 600;
  display: block;
}

.nav-home a:hover,
.nav-list a:hover {
  color: #221;
}

.coming-soon {
  color: gray;
}
.coming-soon:hover {
  color: gray;
}

@media (min-width: 200px) {
  .nav-main,
  .nav-list {
    display: flex;
  }
  .nav-home {
    visibility: hidden;
    height: 0px;
  }
  .nav-main {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }
}

@media (min-width: 769px) {
  .nav-main,
  .nav-list {
    display: flex;
  }
  .nav-home {
    visibility: visible;
    height: 1.45em;
  }
  .nav-main {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }
}

@media (min-width: 1025px) {
  .nav-home {
    visibility: visible;
    height: 1.45em;
  }
  .nav-main {
    flex-direction: row;
    justify-content: space-between;
  }
}
