.footer-container {
  min-height: 20vh;
  background-color: #cca;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}
.footer-label {
  font-size: 48px;
  text-align: center;
}

.footer-links,
.footer-contact {
  display: inline-block;
  list-style: none;
}

.footer-links ul {
  display: flex;
  list-style: none;
  justify-content: space-around;
}

.footer-links,
.footer-contact {
  color: #664;
  text-align: center;
  padding-bottom: 1em;
}

.footer-links li a {
  color: #664;
}

.footer-links li a:hover {
  color: #221;
}

@media (min-width: 200px) {
  .footer-contact {
    font-size: 10px;
  }
  .footer-label {
    font-size: 20px;
  }
}

@media (min-width: 769px) {
  .footer-contact li {
    font-size: 14px;
  }
  .footer-label {
    font-size: 32px;
  }
}
